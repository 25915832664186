import React, { useState, useRef } from "react";
import UploadComponent from './UploadComponent'; // Ensure this path matches where your UploadComponent file is located

function Component() {
  const [columns, setColumns] = useState([{ name: "", type: "" }]);
  const [apiResponses, setApiResponses] = useState([]);
  const [selectedApi, setSelectedApi] = useState(null);
  const uploadComponentRef = useRef(null);

  const handleInputChange = (index, event) => {
    const values = [...columns];
    values[index][event.target.name] = event.target.value;
    setColumns(values);
  };

  const handleAddColumn = () => {
    setColumns([...columns, { name: "", type: "" }]);
  };

  const handleRemoveColumn = (index) => {
    const values = [...columns];
    values.splice(index, 1);
    setColumns(values);
  };

  const handleUploadSuccess = (apiData) => {
    console.log('APIs generated:', apiData);
    setApiResponses(apiData);
  };

  const handleSubmit = () => {
    if (uploadComponentRef.current) {
      uploadComponentRef.current.handleFileUpload();
    }
  };

  const handleDeploy = (api) => {
    setSelectedApi(api);
  };

  const closeModal = () => {
    setSelectedApi(null);
  };

  return (
    <>
      <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-r from-blue-500 to-purple-600 text-white">
        <div className="container mx-auto px-4 md:px-6">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="space-y-2">
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl">
                Harness the power of AI without a team of engineers <span className="text-yellow-400">dimBase ✨</span>
              </h1>
              <p className="mx-auto max-w-[700px] md:text-xl">
                Turn LLM feature ideas into production-grade APIs with one click - no coding required
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto mt-8">
              <div className="space-y-4 text-left">
                <h2 className="text-3xl font-bold sm:text-4xl md:text-5xl px-4 py-20">
                  <span className="text-yellow-400">Ship</span> AI microservices in under 5 minutes
                </h2>
                <p>
                  Increase the ship rate of your ML team's data products faster - we help you ideate & deploy APIs in a few clicks
                </p>
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                  onClick={() => window.location.href = "https://gju6wciqmt9.typeform.com/to/kvxe6nUf"}
                >
                  Join the Waitlist
                </button>
              </div>
              <div className="flex justify-end items-center">
                <video
                  className="rounded-lg shadow-2xl transition-transform transform hover:scale-105"
                  width="600"
                  height="400"
                  controls
                  autoPlay
                  loop
                  muted
                >
                  <source src="CodingVideo.mov" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="w-full max-w-4xl mt-16">
              <UploadComponent ref={uploadComponentRef} onUploadSuccess={handleUploadSuccess} />
              <button
                onClick={handleSubmit}
                className="mt-8 w-full bg-green-600 text-white py-4 text-xl font-semibold rounded-lg hover:bg-green-700">
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
      {apiResponses.length > 0 && (
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-2xl font-bold mb-4">Generated API Ideas:</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {apiResponses.map((api, index) => (
                <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform cursor-pointer">
                  <h3 className="text-2xl font-bold text-gray-800 mb-2">{api.title}</h3>
                  <div className="bg-gray-300 p-2 rounded-lg">
                    <p className="text-lg text-gray-700">{api.description}</p>
                  </div>
                  <details className="mt-4 p-3 bg-gray-200 rounded-lg shadow text-black">
                    <summary className="text-lg font-semibold cursor-pointer hover:text-blue-700">Prompt</summary>
                    <div className="mt-2">
                      <textarea
                        className="w-full p-4 text-lg leading-tight bg-white border-2 border-gray-300 rounded-md"
                        defaultValue={api.sample_prompt}
                      />
                      <ul className="list-disc list-inside text-lg mt-3">
                        {api.new_columns.map((col, index) => (
                          <li key={index} className="ml-4">
                            {col}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </details>
                  <button
                    onClick={() => handleDeploy(api)}
                    className="mt-4 px-4 py-2 bg-orange-600 text-white text-lg font-bold rounded-lg hover:bg-orange-700"
                  >
                    Deploy this API to Production
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {selectedApi && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md mx-auto">
            <div className="space-y-4">
              <div>
                <h2 className="text-2xl font-bold">{selectedApi.title}</h2>
                <p className="text-muted-foreground">
                  {selectedApi.description}
                </p>
              </div>
              <div className="space-y-2">
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="features"
                >
                  <div className="flex flex-col space-y-1">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th scope="col" className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Feature
                          </th>
                          <th scope="col" className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {Object.entries(selectedApi.features_used).map(([feature, description], index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{feature}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </label>
                <textarea
                  className="flex min-h-[80px] w-full px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 rounded-md border border-input bg-background text-foreground"
                  id="sample-prompt"
                  defaultValue={selectedApi.sample_prompt}
                  rows="3"
                ></textarea>
              </div>
              <div className="space-y-2">
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="new-columns"
                >
                  New Columns
                </label>
                <div className="flex flex-col space-y-1">
                  {selectedApi.new_columns.map((col, index) => (
                    <span key={index} className="px-3 py-1 bg-gray-200 rounded-md">{col}</span>
                  ))}
                </div>
              </div>
              <button
                onClick={closeModal}
                className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-primary/90 h-10 px-4 py-2 w-full rounded-md bg-primary text-primary-foreground"
              >
                Generate API
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
