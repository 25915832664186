import React, { useState, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';

const UploadComponent = forwardRef(({ onUploadSuccess }, ref) => {
  const [file, setFile] = useState(null);
  const [apiResponses, setApiResponses] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      setError(''); // Clear any existing errors
      setUploadError('');
    } else {
      setError('At this time dimBase only accepts CSV files.');
      setApiResponses([]); // Clear any existing responses
      setFile(null);
      setUploadError('');
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;

    setIsLoading(true);
    setUploadError('');

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://dim-base-backend.vercel.app/generate-api-use-cases', formData, {
        
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setApiResponses(response.data.use_cases);
      if (onUploadSuccess) {
        onUploadSuccess(response.data.use_cases);
      }
      console.log(response.data);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setUploadError('Something broke, please try again.');
      } else {
        console.error('Error uploading file:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleFileUpload
  }));

  return (
    <div className="text-center p-4">
      <div className="mb-4">
        <label
          htmlFor="file-upload"
          className="block w-full p-10 text-lg font-medium text-center text-white bg-black border-4 border-dashed border-gold-500 rounded-3xl cursor-pointer hover:bg-gray-900"
        >
          {file ? file.name : 'Turn your CSV file into an LLM API!'}
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {isLoading && (
        <div className="w-full bg-gray-200 rounded-full h-4 mt-4">
          <div className="bg-blue-600 h-4 rounded-full animate-pulse" style={{ width: '100%' }}></div>
        </div>
      )}
      {error && <p className="mt-2 text-red-500">{error}</p>}
      {uploadError && <div className="mt-4 p-4 bg-red-200 text-red-700 rounded-lg">{uploadError}</div>}
      {apiResponses.length === 0 && isLoading && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="bg-gray-200 p-4 rounded-lg shadow-md h-48 animate-pulse"></div>
          ))}
        </div>
      )}
    </div>
  );
});

export default UploadComponent;
