import React from "react";
import Landingpage from "./landingpage";
import "./App.css"; // Add this line to include Tailwind CSS

function App() {
  return (
    <div className="App">
      <Landingpage />
    </div>
  );
}

export default App;

